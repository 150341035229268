<template>
    <div class="modal-f-t">
        <div class="itme-scroll flex-1">
            <div v-for="(mistake_item, index) in mistakes_form" :key="index + 'mistakes_form'" class="nav-meni-itmes-modal text-center">
                <el-checkbox v-model="mistake_item.is_checked">{{ mistake_item.name }}</el-checkbox>
            </div>            
        </div>
        <el-row class="btns">
            <el-button @click="submit()" type="success" plain>{{ $t('message.save') }}</el-button>
            <el-button @click="close()"  type="warning" plain>{{ $t('message.close') }}</el-button>
        </el-row>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import drawer from '@/utils/mixins/includes/drawer';

export default {
    mixins: [drawer],
    props:['mistakes', 'sale_product_process_id'],
    data() {
        return {
            mistakes_form: [],
        };
    },
    computed: {
        ...mapGetters({
            
        })
    },
    methods: {
        ...mapActions({
            getEmployeeMistakes: "saleQualityControls/getEmployeeMistakes",
        }),
        async afterOpen() {            
            this.mistakes_form = [];
            if (this.sale_product_process_id) {
                await this.getEmployeeMistakes(this.sale_product_process_id)
                    .then(res => {
                        res.data.employee_mistakes.forEach(element => {
                            let old_selected_mistake = _.find(this.mistakes, ['mistake_id', element.id]);
                            
                            let is_checked = false;
                            if (old_selected_mistake) {
                                is_checked = true;
                            }

                            this.mistakes_form.push({
                                id: element.id,
                                name: element.name,
                                is_checked: is_checked,
                            });
                        });
                    })
                    .catch(err => {
                        this.$alert(err);
                    })
            }
            else {
                this.$message({
                    message: this.$t('message.Process not selected'),
                    type: 'warning',
                    showClose: true
                });
            }
        },
        submit() {
            if (!_.find(this.mistakes_form, ['is_checked', true])) {
                this.$message({
                    message: this.$t('message.Not selected'),
                    type: 'warning',
                    showClose: true
                });
                return;
            }

            let checked_mistakes = _.filter(this.mistakes_form, ['is_checked', true]);
            this.$emit('set-mistakes', {
                mistakes: _.map(checked_mistakes, function(item) {
                    return {
                        mistake_id: item.id,
                        mistake_name: item.name,
                    }
                })
            })
            this.close();
        }
    },
};
</script>

<style>
</style>