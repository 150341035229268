<template>
    <div class="modal-f-t">
        <div class="itme-scroll flex-1">
            <div v-for="(sale, index) in sales" :key="'sales-' + index" class="nav-meni-itmes-modal text-center">
                <el-radio v-model="radio" :label="sale.id">
                    {{ $t('message.sale') + ' № ' + sale.id }}
                </el-radio>
            </div>
        </div>
        <el-row class="btns">
            <el-button @click="submit()" type="success" plain>{{ $t('message.save') }}</el-button>
            <el-button @click="close()"  type="warning" plain>{{ $t('message.close') }}</el-button>
        </el-row>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import drawer from '@/utils/mixins/includes/drawer';

export default {
    mixins: [drawer],
    props:['sale_id'],
    data() {
        return {
            radio: '',
        };
    },
    computed: {
        ...mapGetters({
            sales: 'sales/inventory'
        })
    },
    methods: {
        ...mapActions({
            loadInventory: "sales/inventory",
        }),
        async afterOpen() {
            this.radio = this.sale_id ? this.sale_id : null;
            if (!_.size(this.sales)) await this.loadInventory()
        },
        submit() {
            if (!this.radio) {
                this.$message({
                    message: this.$t('message.Not selected'),
                    type: 'warning',
                    showClose: true
                });
                return;
            }

            let sale = _.find(this.sales, ['id', this.radio]);
            this.$emit('set-sale', {
                sale_id: this.radio,
                sale: sale,
            })
            this.close();
        }
    },
};
</script>
