<template>
    <div class="vw2">
        <div class="top-icon font12">
            <router-link :to="{ name: 'MainLayoutHome' }" class="bg-2">
                <i class="el-icon-arrow-left"></i> {{ $t('message.Back') }}
            </router-link>
            <span>{{ $t('message.Quality control') }}</span>
            <el-button @click="logout()" type="danger" class="el-icon-unlock color-pora">
                {{ $t('message.Exit') }}
            </el-button>
        </div>
        <div class="my-tabs-card">
            <el-tabs v-model="activeName" :before-leave="beforeLeaveTab" type="card">
                <el-tab-pane name="first" :label="$t('message.Working shift')">
                    <div class="nav-meni-itmes" @click="workingShiftDrawer = true">
                        <i class="el-icon-document left-icon"></i>
                        <div class="name-p">
                            <div class="t-name">{{ form_initial_data.working_shift ? (form_initial_data.working_shift.name + ' (' + form_initial_data.working_shift.start_time + ' - ' + form_initial_data.working_shift.end_time + ')') : $t('message.Not selected') }}</div>
                            <div class="b-name">{{ $t('message.Working shift') }}</div>
                        </div>
                        <i class="el-icon-arrow-right right-icon"></i>
                    </div>
                    <div class="d-flex">
                        <div
                            class="nav-meni-itmes flex-1"
                            @click="saleDrawer = true"
                        >
                            <i class="el-icon-setting left-icon"></i>
                            <div class="name-p">
                                <div class="t-name">{{ form_initial_data.sale_id ? ($t('message.sale') + ' № ' + form_initial_data.sale_id) : $t('message.Not selected') }}</div>
                                <div class="b-name">{{ $t('message.sale') }}:</div>
                            </div>
                        </div>
                        <div class="nav-meni-itmes ml-30">
                            <div @click="saleScanDrawer = true">
                                <img
                                    class="scan-img"
                                    src="./../../../public/img/qr-code-scan.png"
                                    alt=""
                                />
                            </div>
                        </div>
                    </div>
                    <div class="d-flex">
                        <div
                            class="nav-meni-itmes flex-1"
                            @click="saleProductDrawer = true"
                        >
                            <i class="el-icon-setting left-icon"></i>
                            <div class="name-p">
                                <div class="t-name">{{ (form_initial_data.sale_product && form_initial_data.sale_product.product) ? form_initial_data.sale_product.product.name : $t('message.Not selected') }}</div>
                                <div class="b-name">{{ $t('message.product') }}:</div>
                            </div>
                        </div>
                        <div class="nav-meni-itmes ml-30">
                            <div @click="saleProductScanDrawer = true">
                                <img
                                    class="scan-img"
                                    src="./../../../public/img/qr-code-scan.png"
                                    alt=""
                                />
                            </div>
                        </div>
                    </div>
                    
                    <div>
                        <div class="text-center">
                            <el-button @click="submitInitialDataForQualityControl()" :loading="loading" type="success" class="btn-100 btn-save">
                                {{ $t('message.save') }}
                            </el-button>
                        </div>
                    </div>
                </el-tab-pane>
                <el-tab-pane name="second" :label="$t('message.Result')">
                    <el-row :gutter="20">
                        <el-col :span="12">
                            <div class="nav-meni-itmes" @click="saleProductProcessDrawer = true">
                                <i class="el-icon-sort left-icon"></i>
                                <div class="name-p">
                                    <div class="t-name">{{ (form.sale_product_process && form.sale_product_process.process) ? form.sale_product_process.process.name : $t('message.Not selected') }}</div>
                                    <div class="b-name">{{ $t('message.Process') }}:</div>
                                </div>
                                <i class="el-icon-arrow-right right-icon"></i>
                            </div>

                            <div class="nav-meni-itmes" @click="checkTimeDrawer = true">
                                <i class="el-icon-odometer left-icon"></i>
                                <div class="name-p">
                                    <div class="t-name">
                                        {{ (form && form.check_time) ? (form.check_time.name + ' (' + form.check_time.start_time + ' - ' + form.check_time.end_time + ')') : $t('message.Not selected') }}
                                    </div>
                                    <div class="b-name">{{ $t('message.Check') }}:</div>
                                </div>
                                <i class="el-icon-arrow-right right-icon"></i>
                            </div>

                            <div @click="employeeScanDrawer = true" class="d-flex">
                                <div
                                    class="nav-meni-itmes flex-1"
                                >
                                    <i class="el-icon-setting left-icon"></i>
                                    <div class="name-p">
                                        <div class="t-name">{{ form.employee ? form.employee.name : $t('message.Not selected') }}</div>
                                        <div class="b-name">{{ $t('message.Employee') }}</div>
                                    </div>
                                </div>
                                <div class="nav-meni-itmes ml-30">
                                    <div>
                                        <img
                                            class="scan-img"
                                            src="./../../../public/img/qr-code-scan.png"
                                            alt=""
                                        />
                                    </div>
                                </div>
                            </div>

                            <div @click="machineScanDrawer = true" class="d-flex">
                                <div
                                    class="nav-meni-itmes flex-1"
                                >
                                    <i class="el-icon-setting left-icon"></i>
                                    <div class="name-p">
                                        <div class="t-name">{{ form.machine ? (form.machine.name + ' (' + form.machine.code + ')') : $t('message.Not selected') }} | {{ form.bant  ? form.bant.name : '' }}</div>
                                        <div class="b-name">{{ $t('message.Machine') }} | {{ $t('message.Bant') }}</div>
                                    </div>
                                </div>
                                <div class="nav-meni-itmes ml-30">
                                    <div>
                                        <img
                                            class="scan-img"
                                            src="./../../../public/img/qr-code-scan.png"
                                            alt=""
                                        />
                                    </div>
                                </div>
                            </div>

                            <div class="nav-meni-itmes">
                                  <div class="b-name w-210">{{ $t('message.Number of verified') }}</div>
                                    <div class="input-number-khan">  
                                    <el-input-number
                                        v-model="form.checked_quantity"
                                        :min="0"
                                        :max="10"
                                    ></el-input-number>
                               </div>
                            </div>

                            <div class="nav-meni-itmes">
                                  <div class="b-name w-210"> {{ $t('message.Not passed OTK') }}</div>
                                    <div class="input-number-khan">  
                                    <el-input-number
                                            v-model="form.defect_quantity"
                                            :min="0"
                                            :max="10"
                                        ></el-input-number>
                               </div>
                            </div>

                            <div class="nav-meni-itmes" @click="mistakesDrawer = true">
                                <i class="el-icon-circle-close left-icon"></i>
                                <div class="name-p">
                                    <div class="t-name">
                                        <span v-for="(mistake_item, index) in form.mistakes" :key="'mistakes-' + index">
                                            <template v-if="index == 0">{{ mistake_item.mistake_name }}</template>
                                            <template v-else>{{ ', ' + mistake_item.mistake_name }}</template>
                                        </span>
                                    </div>
                                    <div class="b-name">{{ $t('message.Mistakes') }}:</div>
                                </div>
                                <i class="el-icon-arrow-right right-icon"></i>
                            </div>
                        </el-col>
                        <!-- end  -->

                        <el-col :span="12">
                            <div v-for="(item, index2) in quality_control_times_history" :key="index2 + 'quality_control_times_history'" :class="getClassItem(item)">
                                <div class="itme-r-u">
                                    <span>{{ $t('message.Working shift') }}:</span>
                                    <b>{{ getWorkingShiftName(item) }}</b>
                                </div>
                                <div class="itme-r-u">
                                    <span>{{ $t('message.sale') + ' №' }}:</span>
                                    <b>{{ (item.quality_control_employee && item.quality_control_employee.sale_quality_control) ? item.quality_control_employee.sale_quality_control.sale_id : '' }}</b>
                                </div>
                                <div class="itme-r-u">
                                    <span>{{ $t('message.product') }}:</span>
                                    <b>{{ (item.quality_control_employee && item.quality_control_employee.sale_quality_control && item.quality_control_employee.sale_quality_control.sale_product && item.quality_control_employee.sale_quality_control.sale_product.product) ? item.quality_control_employee.sale_quality_control.sale_product.product.name : '' }}</b>
                                </div>
                                <div class="itme-r-u">
                                    <span>{{ $t('message.Process') }}:</span>
                                    <b>{{ (item.quality_control_employee && item.quality_control_employee.sale_product_process && item.quality_control_employee.sale_product_process.process) ? item.quality_control_employee.sale_product_process.process.name : '' }}</b>
                                </div>
                                <div class="itme-r-u">
                                    <span>{{ $t('message.Check') }}:</span>
                                    <b>{{ (item.check_time) ? (item.check_time.name + ' (' + item.check_time.start_time + ' - ' + item.check_time.end_time + ')') : '' }}</b>
                                </div>
                                <div class="itme-r-u">
                                    <span>{{ $t('message.Bant') }}:</span>
                                    <b>{{ (item.quality_control_employee && item.quality_control_employee.bant) ? item.quality_control_employee.bant.name : '' }}</b>
                                </div>
                                <div class="itme-r-u">
                                    <span>{{ $t('message.Employee') }}:</span>
                                    <b>{{ (item.quality_control_employee && item.quality_control_employee.employee) ? item.quality_control_employee.employee.name : '' }}</b>
                                </div>
                                <div class="itme-r-u">
                                    <span>{{ $t('message.Machine') }}:</span>
                                    <b>{{ (item.quality_control_employee && item.quality_control_employee.machine) ? (item.quality_control_employee.machine.name + ' (' + item.quality_control_employee.machine.code + ')') : '' }}</b>
                                </div>
                                <div class="itme-r-u">
                                    <span>{{ $t('message.Number of verified') }}:</span>
                                    <b>{{ item.checked_quantity }}</b>
                                </div>
                                <div class="itme-r-u">
                                    <span>{{ $t('message.Not passed OTK') }}:</span>
                                    <b>{{ item.defect_quantity }}</b>
                                </div>
                                <div class="itme-r-u">
                                    <span>{{ $t('message.Mistakes') }}:</span>
                                    <b>{{ getMistakes(item) }}</b>
                                </div>
                                <div class="itme-r-u">
                                    <span>{{ $t('message.Measurement time') }}:</span>
                                    <b>{{ item.created_at }}</b>
                                </div>
                            </div>
                        </el-col>
                    </el-row>

                    <div>
                        <div class="text-center">
                            <el-button @click="submit()" :loading="loading" type="success" class="btn-100 btn-save">
                                {{ $t('message.save') }}
                            </el-button>
                        </div>
                    </div>
                </el-tab-pane>
            </el-tabs>
        </div>

        <!-- Modals  -->
        <el-drawer
            class="modal-style"
            :direction="direction"
            :visible.sync="workingShiftDrawer"
            size="80%"
            ref="workingShiftDrawer" 
            @closed="drawerClosed('workingShiftDrawerChild')" 
            @opened="drawerOpened('workingShiftDrawerChild')"
        >
            <working-shift-select 
                :working_shift_id="form_initial_data.working_shift_id"
                @set-working-shift="setWorkingShift"
                drawer="workingShiftDrawer" 
                ref="workingShiftDrawerChild">
            </working-shift-select>
        </el-drawer>

        <el-drawer
            class="modal-style"
            :direction="direction"
            :visible.sync="saleDrawer"
            size="80%"
            ref="saleDrawer" 
            @closed="drawerClosed('saleDrawerChild')" 
            @opened="drawerOpened('saleDrawerChild')"
        >
            <sale-select
                :sale_id="form_initial_data.sale_id"
                @set-sale="setSale"
                drawer="saleDrawer" 
                ref="saleDrawerChild">
            </sale-select>
        </el-drawer>

        <el-drawer
            :with-header="false"
            class=""
            :show-close="false"
            :direction="direction"
            :visible.sync="saleScanDrawer"
            size="100%"
            ref="saleScanDrawer" 
            @closed="drawerClosed('saleScanDrawerChild')" 
            @opened="drawerOpened('saleScanDrawerChild')"
        >
            <sale-scan                
                @set-sale="setSale"
                drawer="saleScanDrawer" 
                ref="saleScanDrawerChild">
            </sale-scan>
        </el-drawer>

        <el-drawer
            class="modal-style"
            :direction="direction"
            :visible.sync="saleProductDrawer"
            size="80%"
            ref="saleProductDrawer"
            @closed="drawerClosed('saleProductDrawerChild')" 
            @opened="drawerOpened('saleProductDrawerChild')"
        >
            <sale-product-select
                :sale_id="form_initial_data.sale_id"
                :sale_product_id="form_initial_data.sale_product_id"
                @set-sale-product="setSaleProduct"
                drawer="saleProductDrawer" 
                ref="saleProductDrawerChild">
            </sale-product-select>
        </el-drawer>

        <el-drawer
            :with-header="false"
            class=""
            :show-close="false"
            :direction="direction"
            :visible.sync="saleProductScanDrawer"
            size="100%"
            ref="saleProductScanDrawer" 
            @closed="drawerClosed('saleProductScanDrawerChild')" 
            @opened="drawerOpened('saleProductScanDrawerChild')"
        >
            <sale-product-scan
                :sale_id="form_initial_data.sale_id"                
                @set-sale-product="setSaleProduct"
                drawer="saleProductScanDrawer" 
                ref="saleProductScanDrawerChild">
            </sale-product-scan>
        </el-drawer>

        <el-drawer
            class="modal-style"
            :direction="direction"
            :visible.sync="saleProductProcessDrawer"
            size="80%"
            ref="saleProductProcessDrawer"
            @closed="drawerClosed('saleProductProcessDrawerChild')" 
            @opened="drawerOpened('saleProductProcessDrawerChild')"
        >
            <sale-product-process-select
                :sale_product_id="form_initial_data.sale_product_id"
                :sale_product_process_id="form.sale_product_process_id"
                @set-sale-product-process="setSaleProductProcess"
                drawer="saleProductProcessDrawer" 
                ref="saleProductProcessDrawerChild">
            </sale-product-process-select>
        </el-drawer>

        <el-drawer
            class="modal-style"
            :direction="direction"
            :visible.sync="checkTimeDrawer"
            size="80%"
            ref="checkTimeDrawer"
            @closed="drawerClosed('checkTimeDrawerChild')" 
            @opened="drawerOpened('checkTimeDrawerChild')"
        >
            <check-time-select
                :check_time_id="form.check_time_id"
                @set-check-time="setCheckTime"
                drawer="checkTimeDrawer" 
                ref="checkTimeDrawerChild">

            </check-time-select>
        </el-drawer>

        <el-drawer
            :with-header="false"
            class=""
            :show-close="false"
            :direction="direction"
            :visible.sync="employeeScanDrawer"
            size="100%"
            ref="employeeScanDrawer" 
            @closed="drawerClosed('employeeScanDrawerChild')" 
            @opened="drawerOpened('employeeScanDrawerChild')"
        >
            <employee-scan
                @set-employee="setEmployeeScan"
                drawer="employeeScanDrawer" 
                ref="employeeScanDrawerChild">
            </employee-scan>
        </el-drawer>

        <el-drawer
            :with-header="false"
            class=""
            :show-close="false"
            :direction="direction"
            :visible.sync="machineScanDrawer"
            size="100%"
            ref="machineScanDrawer" 
            @closed="drawerClosed('machineScanDrawerChild')" 
            @opened="drawerOpened('machineScanDrawerChild')"
        >
            <machine-scan
                @set-machine="setMachineScan"
                drawer="machineScanDrawer" 
                ref="machineScanDrawerChild">
            </machine-scan>
        </el-drawer>
        
        <el-drawer
            class=""
            :show-close="false"
            :direction="direction"
            :visible.sync="mistakesDrawer"
            size="100%"
            ref="mistakesDrawer" 
            @closed="drawerClosed('mistakesDrawerChild')" 
            @opened="drawerOpened('mistakesDrawerChild')"
        >
            <mistakes-select
                :mistakes="form.mistakes"
                :sale_product_process_id="form.sale_product_process_id"
                @set-mistakes="setMistakes"
                drawer="mistakesDrawer" 
                ref="mistakesDrawerChild">
            </mistakes-select>
        </el-drawer>
    </div>
</template>
<script>
import WorkingShiftSelect from "./components/working-shift-select.vue";
import SaleSelect from "./components/sale-select.vue";
import SaleScan from "./components/sale-scan.vue";
import SaleProductSelect from "./components/sale-product-select.vue";
import SaleProductScan from "./components/sale-product-scan.vue";
import SaleProductProcessSelect from "./components/sale-product-process-select.vue";
import CheckTimeSelect from "./components/check-time-select.vue";
import EmployeeScan from "./components/employee-scan.vue";
import MachineScan from "./components/machine-scan.vue";
import MistakesSelect from "./components/mistakes-select.vue";

import { mapActions, mapGetters } from 'vuex';

export default {
    components: {
        WorkingShiftSelect,
        SaleSelect,
        SaleScan,
        SaleProductSelect,
        SaleProductScan,
        SaleProductProcessSelect,
        CheckTimeSelect,
        EmployeeScan,
        MachineScan,
        MistakesSelect,
    },
    data() {
        return {
            form_initial_data: {},
            form: {},
            activeName: "first",
            workingShiftDrawer: false,
            saleDrawer: false,
            saleScanDrawer: false,
            saleProductDrawer: false,
            saleProductScanDrawer: false,
            saleProductProcessDrawer: false,            
            checkTimeDrawer: false,
            employeeScanDrawer: false,
            machineScanDrawer: false,
            mistakesDrawer: false,
            loading: false,
            activeNameControlHistory: '0-name',
            direction: "btt",
            quality_control_times_history: [],
        };
    },
    created() {
        this.activeName = 'first';
        this.activeNameControlHistory = '0-name';
        this.form_initial_data = JSON.parse(JSON.stringify(this.getFormInitialData));
        this.form = JSON.parse(JSON.stringify(this.getForm));
        this.loadInitialDataForQualityControl();
    },
    computed: {
        ...mapGetters({            
            getFormInitialData: 'saleQualityControls/form_initial_data',
            getForm: 'saleQualityControls/form',
        }),
    },
    methods: {
        ...mapActions({
            save: "saleQualityControls/store",
            getInitialDataForQualityControl: "saleQualityControls/getInitialDataForQualityControl",
            createInitialData: "saleQualityControls/createInitialData",
            getSaleQualityControlTimesHistoryOfEmployee: "saleQualityControls/getSaleQualityControlTimesHistoryOfEmployee",
        }),
        loadInitialDataForQualityControl() {
            this.getInitialDataForQualityControl()
                .then(res => {
                    let sale_quality_control = res.data.sale_quality_control;
                    if (sale_quality_control) {
                        this.form_initial_data.working_shift = sale_quality_control.working_shift;
                        this.form_initial_data.working_shift_id = sale_quality_control.working_shift_id;
                        this.form_initial_data.sale = sale_quality_control.sale;
                        this.form_initial_data.sale_id = sale_quality_control.sale_id;
                        this.form_initial_data.sale_product = sale_quality_control.sale_product;
                        this.form_initial_data.sale_product_id = sale_quality_control.sale_product_id;

                        this.form.sale_quality_control_id = sale_quality_control.id;
                    }
                }).catch(err => {

                });
        },
        submitInitialDataForQualityControl() {
            if (!this.loading) {
                this.loading = true;
                this.createInitialData(this.form_initial_data)
                    .then(res => {
                        let sale_quality_control = res.data.sale_quality_control;
                        this.form.sale_quality_control_id = sale_quality_control.id;
                        this.$alert(res);
                        this.loading = false;
                    })
                    .catch(err => {
                        this.loading = false;
                        this.$alert(err);
                    })
            }
        },
        submit() {
            if (!this.loading) {
                this.loading = true;
                this.save(this.form)
                    .then(res => {
                        // soxranitdan keyin keyingi proverka uchun danniyni tayyorlash
                        let sale_quality_control_id = this.form.sale_quality_control_id;
                        this.form = JSON.parse(JSON.stringify(this.getForm)); // shu yerda this.form.sale_quality_control_id tozalanadi. shuning uchun eski qiymat olinib boshqattan set qilinvotti
                        this.form.sale_quality_control_id = sale_quality_control_id;
                        this.$alert(res);
                        this.loading = false;
                    })
                    .catch(err => {
                        this.loading = false;
                        this.$alert(err);
                    })
            }
        },
        setWorkingShift(data) {
            if (data) {
                this.form_initial_data.working_shift_id = data.working_shift_id;
                this.form_initial_data.working_shift = data.working_shift;
            }
        },
        setSale(data) {
            if (data) {
                // agar zayavka ozgartirilayotgan bolsa produktni va processni tozalash
                if (this.form_initial_data.sale_id != data.sale_id) {
                    this.form_initial_data.sale_product_id = null;
                    this.form_initial_data.sale_product = null;

                    this.form.sale_product_process_id = null;
                    this.form.sale_product_process = null;
                }

                this.form_initial_data.sale_id = data.sale_id;
                this.form_initial_data.sale = data.sale;
            }
        },
        setSaleProduct(data) {
            if (data) {
                // agar sale_product ozgartirilayotgan bolsa processni tozalash                
                if (this.form_initial_data.sale_product_id != data.sale_product_id) {
                    this.form.sale_product_process_id = null;
                    this.form.sale_product_process = null;
                }

                this.form_initial_data.sale_product_id = data.sale_product_id;
                this.form_initial_data.sale_product = data.sale_product;
            }
        },
        setSaleProductProcess(data) {
            if (data) {
                this.form.sale_product_process_id = data.sale_product_process_id;
                this.form.sale_product_process = data.sale_product_process;

                this.form.mistakes = [];
            }
        },
        setCheckTime(data) {
            if (data) {
                this.form.check_time_id = data.check_time_id;
                this.form.check_time = data.check_time;
            }
        },
        setEmployeeScan(data) {
            if (data) {
                this.quality_control_times_history = [];

                this.form.employee_id = data.employee_id;
                this.form.employee = data.employee;
                
                let params = {
                    sale_quality_control_id: this.form.sale_quality_control_id,
                    employee_id: data.employee_id,
                }
                
                this.getSaleQualityControlTimesHistoryOfEmployee(params).then(res => {                    
                    this.quality_control_times_history = res.data.quality_control_times;
                }).catch(err => {
                    this.$alert(err);
                });

                // TODO:: oldingi kontrollariga qarab "checked_quantity" ni sonini avtomatik olish
            }
        },
        setMachineScan(data) {
            if (data) {
                this.form.machine_id = data.machine_id;
                this.form.machine = data.machine;
                this.form.bant_id = data.bant_id;
                this.form.bant = data.bant;
            }
        },
        setMistakes(data) {
            if (data) {                
                this.form.mistakes = data.mistakes;
            }
        },
        beforeLeaveTab(newVal) {
            if (newVal == 'second') {
                if (!this.form.sale_quality_control_id) {
                    this.$message({
                        message: this.$t('message.Required data not selected or not saved'),
                        type: 'warning',
                        showClose: true
                    });

                    return false;
                }
            }
        },

        /* Methods for History */
        getClassItem(item) {
            let class_name = 'rght-user-info-re ';

            if (item.defect_quantity == 0) {
                class_name += 'good';
            }
            else if (item.defect_quantity > 0) {
                class_name += 'bed';
            }

            return class_name;
        },
        getWorkingShiftName(item) {
            let working_shift = (item.quality_control_employee && item.quality_control_employee.sale_quality_control && item.quality_control_employee.sale_quality_control.working_shift) ? item.quality_control_employee.sale_quality_control.working_shift : null;

            if (working_shift) {
                return (working_shift.name + ' (' + working_shift.start_time + ' - ' + working_shift.end_time + ')');
            }
            else {
                return ''
            }
        },
        getMistakes(item) {
            var result = '';
            _.forEach(item.employee_mistakes, function(item2, key) {
                if (key === 0) {
                    result += item2.name;
                }
                else {
                    result += (', ' + item2.name);
                }
            });

            return result;
        },

        closeDrawer(drawer) {
            if (this.$refs[drawer] && _.isFunction(this.$refs[drawer].closeDrawer)) {
                this.$refs[drawer].closeDrawer();
            }
        },
        drawerClosed(ref) {
            if (this.$refs[ref]) {
                if (_.isFunction(this.$refs[ref].closed)) {
                    this.$refs[ref].closed()
                }
            }
        },
        drawerOpened(ref) {
            if (this.$refs[ref]) {
                if (_.isFunction(this.$refs[ref].opened)) {
                    this.$refs[ref].opened()
                }
            }
        },
        logout() {
            this.$confirm('Вы уверены, что хотите выйти?', 'Подтверждение', {
                    confirmButtonText: 'Да',
                    cancelButtonText: 'Нет',
                    type: 'warning'
                }).then(async () => {
                    await this.$store.dispatch("auth/logout");
                    this.$router.push(`/login?redirect=${this.$route.fullPath}`);
                }).catch(() => {
                
                });
        },
    },
};
</script>