<template>
    <div>
        <div class="top-icon font12 scan-top-title">
            <el-button @click="close()" type="warning" class="el-icon-arrow-left">
                {{ $t('message.Back') }}
            </el-button>
            <span>{{ $t('message.Scan QR code') }} {{ $t('message.product') }}</span>
            <el-button @click="logout()" type="danger" class="el-icon-unlock color-pora">
                {{ $t('message.Exit') }}
            </el-button>
        </div>
        <div :loading="loadingData" class="scan-full">
            <div class="scan">
                <el-input v-model="input" @keydown.enter.native="onEnter" @blur="$refs.scanInput.focus()" ref="scanInput" placeholder="Please input"></el-input>
                <img
                    class="scan-img"
                    src="./../../../../public/img/qr-code-scan.png"
                    alt=""
                />
            </div>
        </div>
    </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import drawer from '@/utils/mixins/includes/drawer';

export default {
    props:['sale_id'],
    mixins: [drawer],
    data() {
        return {
            input: "",
            loadingData: false,
        };
    },
    mounted() {
        this.debouncedScanSaleProduct = _.debounce(this.getScanSaleProductQRCode, 500)
    },
    watch: {
        input: function (value) {
            if (value && value != '') {
                this.debouncedScanSaleProduct();
            }
        },
    },
    methods: {
        ...mapActions({
            scanSaleProductQRCode: 'sales/scanSaleProductQRCode',
        }),
        async afterOpen() {
            this.loadingData = false;
            this.input = '';
            this.$refs.scanInput.focus();
        },
        getScanSaleProductQRCode() {
            if (!this.loadingData && this.input) {
                this.loadingData = true;
                let params = {
                    sale_id: this.sale_id,
                    product_code: this.input,
                }
                this.scanSaleProductQRCode(params).then(res => {
                    let sale_product = res.data.sale_product;

                    this.input = '';
                    this.$refs.scanInput.focus();                    
                    this.loadingData = false;

                    this.$emit('set-sale-product', {
                        sale_product_id: sale_product.id,
                        sale_product: sale_product,
                    })
                    this.close();
                }).catch(err => {
                    this.input = '';
                    this.$refs.scanInput.focus();
                    this.$alert(err);
                    this.loadingData = false
                });
            }
        },
        onEnter(event) {
            event.preventDefault()
            this.getScanSaleProductQRCode();
        },
        logout() {
            this.$confirm('Вы уверены, что хотите выйти?', 'Подтверждение', {
                    confirmButtonText: 'Да',
                    cancelButtonText: 'Нет',
                    type: 'warning'
                }).then(async () => {
                    await this.$store.dispatch("auth/logout");
                    this.$router.push(`/login?redirect=${this.$route.fullPath}`);
                }).catch(() => {
                
                });
        },
    },
};
</script>