<template>
    <div class="modal-f-t">
        <div class="itme-scroll flex-1">
            <div v-for="(sale_product, index) in sale_products" :key="'sale_productss-' + index" class="nav-meni-itmes-modal text-center">
                <el-radio v-model="radio" :label="sale_product.id">
                    {{ sale_product.product ? sale_product.product.name : '' }}
                </el-radio>
            </div>
        </div>
        <el-row class="btns">
            <el-button @click="submit()" type="success" plain>{{ $t('message.save') }}</el-button>
            <el-button @click="close()"  type="warning" plain>{{ $t('message.close') }}</el-button>
        </el-row>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import drawer from '@/utils/mixins/includes/drawer';

export default {
    mixins: [drawer],
    props:['sale_id', 'sale_product_id'],
    data() {
        return {
            radio: '',
        };
    },
    computed: {
        ...mapGetters({
            sale_products: 'sales/sale_products_inventory'
        })
    },
    methods: {
        ...mapActions({
            loadInventory: "sales/saleProductInventory",
        }),
        async afterOpen() {
            this.radio = this.sale_product_id ? this.sale_product_id : null;
            if (this.sale_id) await this.loadInventory(this.sale_id)
        },
        submit() {
            if (!this.radio) {
                this.$message({
                    message: this.$t('message.Not selected'),
                    type: 'warning',
                    showClose: true
                });
                return;
            }

            let sale_product = _.find(this.sale_products, ['id', this.radio]);
            this.$emit('set-sale-product', {
                sale_product_id: this.radio,
                sale_product: sale_product,
            })
            this.close();
        }
    },
};
</script>
