<template>
    <div>
        <div class="top-icon font12 scan-top-title">
            <el-button @click="close()" type="warning" class="el-icon-arrow-left">
                {{ $t('message.Back') }}
            </el-button>
            <span>{{ $t('message.Scan QR code') }} {{ $t('message.Employee') }}</span>
            <el-button @click="submit()" type="success" class="color-pora">
                {{ $t('message.save') }}
            </el-button>
        </div>
        <div :loading="loadingData" class="scan-full">
            <div class="scan">
                <el-input v-model="input" @keydown.enter.native="onEnter" @blur="$refs.scanInput.focus()" ref="scanInput" placeholder="Please input"></el-input>
                <img
                    class="scan-img"
                    src="./../../../../public/img/qr-code-scan.png"
                    alt=""
                />
            </div>
        </div>
    </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import drawer from '@/utils/mixins/includes/drawer';

export default {
    mixins: [drawer],
    data() {
        return {
            input: "",
            loadingData: false,
        };
    },
    mounted() {
        this.debouncedScanEmployee = _.debounce(this.getScanEmployeeQRCode, 500)        
    },
    watch: {
        input: function (value) {
            if (value && value != '') {
                this.debouncedScanEmployee();
            }
        },
    },
    methods: {
        ...mapActions({
            scanEmployeeQRCode: 'saleQualityControls/scanEmployeeQRCode',
        }),
        async afterOpen() {
            this.loadingData = false;
            this.input = '';
            this.$refs.scanInput.focus();
        },        
        getScanEmployeeQRCode() {
            if (!this.loadingData && this.input) {
                this.loadingData = true;
                this.scanEmployeeQRCode({employee_id: parseInt(this.input)}).then(res => {
                    let employee = res.data.employee;

                    this.input = '';
                    this.$refs.scanInput.focus();                    
                    this.loadingData = false;

                    this.$emit('set-employee', {
                        employee_id: employee.id,
                        employee: employee,
                    })
                    this.close();
                }).catch(err => {
                    this.input = '';
                    this.$refs.scanInput.focus();
                    this.$alert(err);
                    this.loadingData = false
                });
            }
        },
        onEnter(event) {
            event.preventDefault()
            this.getScanEmployeeQRCode();
        },
        logout() {
            this.$confirm('Вы уверены, что хотите выйти?', 'Подтверждение', {
                    confirmButtonText: 'Да',
                    cancelButtonText: 'Нет',
                    type: 'warning'
                }).then(async () => {
                    await this.$store.dispatch("auth/logout");
                    this.$router.push(`/login?redirect=${this.$route.fullPath}`);
                }).catch(() => {
                
                });
        },
    },
};
</script>