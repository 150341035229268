<template>
    <div class="modal-f-t">
        <div class="itme-scroll flex-1">
            <div v-for="(workingShift, index) in workingShifts" :key="'workingShifts-' + index" class="nav-meni-itmes-modal text-center">
                <el-radio v-model="radio" :label="workingShift.id">
                    {{ workingShift.name + ' (' + workingShift.start_time + ' - ' + workingShift.end_time + ')' }}
                </el-radio>
            </div>
        </div>
        <el-row class="btns">
            <el-button @click="submit()" type="success" plain>{{ $t('message.save') }}</el-button>
            <el-button @click="close()"  type="warning" plain>{{ $t('message.close') }}</el-button>
        </el-row>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import drawer from '@/utils/mixins/includes/drawer';

export default {
    mixins: [drawer],
    props:['working_shift_id'],
    data() {
        return {
            radio: '',
        };
    },
    computed: {
        ...mapGetters({
            workingShifts: 'workingShifts/inventory'
        })
    },
    methods: {
        ...mapActions({
            loadInventory: "workingShifts/inventory",
        }),
        async afterOpen() {
            this.radio = this.working_shift_id ? this.working_shift_id : null;
            if (!_.size(this.workingShifts)) await this.loadInventory()
        },
        submit() {
            if (!this.radio) {
                this.$message({
                    message: this.$t('message.Not selected'),
                    type: 'warning',
                    showClose: true
                });
                return;
            }

            let working_shift = _.find(this.workingShifts, ['id', this.radio]);
            this.$emit('set-working-shift', {
                working_shift_id: this.radio,
                working_shift: working_shift,
            })
            this.close();
        }
    },
};
</script>
